import { dev } from '$app/environment'
import * as Sentry from '@sentry/sveltekit'

Sentry.init({
	enabled: !dev,
	environment: dev ? 'dev' : 'production',
	dsn: 'https://90f2d35dfc664cc59b769b05d13a975e@o1070015.ingest.us.sentry.io/6065506',
	tracesSampleRate: 1,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1,
	integrations: [Sentry.replayIntegration()],
})

export const handleError = Sentry.handleErrorWithSentry()
